.btn-login {
  width: 50%; 
}

.logo {
  max-height: 150px;
}

.cookies-alert {
  display: none;
  justify-content: center;
  color: black;
  margin-bottom: 0px;
}

.cookies-link {
  padding-left: 1%;
}

.main-container {
  min-height: calc(100vh - 145px);
}

@media (max-width: 544px) {  
  .h1-font-size {font-size: 1.5rem;} 
}

@media (min-width: 767px) {
  .main-container {
    min-height: calc(100vh - 142px);
  }
}

@media (min-width: 992px) {
  .main-container {
    min-height: calc(100vh - 119px);
  }
}
