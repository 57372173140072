.invoice {
    display: flex;
    background: #34657f;
    padding: 8px;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
  }
  .invoice-link {
    background: #ffffff;
    padding: 0 2rem;
    border-radius: 2rem;
    font-size: 18px;
    color: #000000;
    margin-right: 0;
  }
  
  body.active-modal {
    overflow-y: hidden;
  
  }
  .overlay {
    background: black;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    opacity: 0.8;
    z-index: 99999999999;
  
  }
  .modal-content {
    position: absolute;
    top: 251px;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.8;
    background: #ffff;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 500px;
    min-width: 300px;
    min-height: 400px;
    color: red;
    font-size: 20px;
    z-index: 999999999999;
  }
  .onlinepay {
    background-color: black;
    color: #f1f1f1;
    width: 150px;
    margin: 10px auto;
    text-align: center;
  }
