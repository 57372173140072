.header {
    /* color: #000000; */
    background-color: #34657f;
    /* padding: 15px; */
    margin-left: -6px;
  }
  .modal.body {
    display: flex;
    overflow: hidden;
    background-color: #ffffff;
  }
  .footer {
    display: flex;
    justify-content: space-between !important;
    background-color: #ffffff;
  }
  .ht-gray-stripe-fill {
    background-repeat: repeat;
    background-position-x: center;
    background-image: url("https://images.ctfassets.net/nac4rsvtoh64/3FBGhXZvG3TDagKv1XlnP/13e23e5dc519637caacde721bcbbd732/ht-gray-stripe-fill.png?fm=webp");
  }
  .min-height-25 {
    min-height: 25px;
  }
  .p-btn{
    height:35px
  }
