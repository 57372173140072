.footer {
  margin-top: 50px;
  min-height: 50px !important;
  background: #ffff;
}

.footer-font-size {
  font-size: 13px;
}
ul.footer-menu{
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
ul.footer-menu li a{
  padding: 0 20px;
  font-size: 17px;
  color: #0073b0;
}
.footer-content{
  font-size: 17px;
}
@media (max-width: 500px) {
  .footer-font-size {
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .footer-font-size {
    font-size: 9px;
  }
}

.link {
  padding-bottom: 26px;
}

.alert-error {
  margin-bottom: calc(100vh - 195px) !important;
}

@media (min-width: 768px) {
  .alert-error {
    margin-bottom: calc(100vh - 192px) !important;
  }
}

@media (min-width: 992px) {
  .alert-error {
    margin-bottom: calc(100vh - 168px) !important;
  }
}
