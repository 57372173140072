@import url(https://fonts.googleapis.com/css?family=Overpass);
.full-height {
  background: #faf8f6;
  font-family: Overpass;
  font-style: normal;
}

.drop-shadow {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.hightower-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}

.synergy-background {
  background-image: url(/static/media/synergy-background.e0538969.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.synergy-button {
  padding: 10px 50px;
  border: 3px solid #1C78BD;
  border-radius: 0px;
  background: #1C78BD;
  color: #ffffff;
}

.tp-button {
  padding: 10px 50px;
  border: 3px solid #337ab7;
  background: #337ab7;
  color: #ffffff;
}

.aline-background {
  background-color: #eee;
}

.aline-button {
  padding: 10px 50px;
  border: 3px solid #0731F7;
  border-radius: 0px;
  background: #0731F7;
  color: #ffffff;
}

.falcon-background {
  background-color: #E2E1D3;
}

.falcon-button {
  padding: 10px 50px;
  border: 3px solid #1D4460;
  border-radius: 0px;
  background: #1D4460;
  color: #ffffff;
}

.morgia-button {
  padding: 10px 50px;
  border: 3px solid #1C78BD;
  border-radius: 0px;
  background: #1C78BD;
  color: #ffffff;
}

.harvey-background {
  background-color: #005983;
}

.harvey-button {
  padding: 10px 50px;
  border: 3px solid #1C78BD;
  border-radius: 0px;
  background: #009D4F;
  color: #ffffff;
}

.tbg-background {
  background-image: url(/static/media/tbg-background.e64dc37a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.tbg-button {
  padding: 10px 50px;
  border: 3px solid #000000;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}

.fortress-background {
  background-color: #eee;
}

.fortress-button {
  padding: 10px 50px;
  border: 3px solid #1d1f2c;
  border-radius: 0px;
  background: #002856;
  color: #ffffff;
}

.cognetic-background {
  background-color: #eee;
}

.cognetic-button {
  padding: 10px 50px;
  border: 3px solid #244fa5;
  border-radius: 0px;
  background: #244fa5;
  color: #ffffff;
}

.hma-background {
  background-color: #eee;
}

.hma-button {
  padding: 10px 50px;
  border: 3px solid #105938;
  border-radius: 0px;
  background: #105938;
  color: #ffffff;
}

.somerset-background {
  background-color: #A3B2A8;
}

.somerset-button {
  padding: 10px 50px;
  border: 3px solid #A3B2A8;
  border-radius: 0px;
  background: #A3B2A8;
  color: #ffffff;
}

.lexington-background {
	background-color: #175878;
}

.lexington-button {
	padding: 10px 50px;
	border: 3px solid #175878;
	border-radius: 0px;
	background: #175878;
	color: #ffffff;
}

.irongate-background {
	background-color: #CCBCA3;
}

.irongate-button {
	padding: 10px 50px;
	border: 3px solid #CCBCA3;
	border-radius: 0px;
	background: #CCBCA3;
	color: #ffffff;
}

.dsg-background {
	background-image: url(/static/media/dsg-background.d5fd9bae.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.dsg-button {
	padding: 10px 50px;
	border: 3px solid #4B4F54;
	border-radius: 0px;
	background: #4B4F54;
	color: #ffffff;
}

.alert-background {
  background-color: #fff3cd;
}

.sign-out {
  color: #000000 !important;
}

.schultz-button {
  padding: 10px 50px;
  border: 3px solid #3D4934;
  border-radius: 0px;
  background: #3D4934;;
  color: #ffffff;
}

.spinner-background {
  background-color: #fff;
}

.stearns-button {
  padding: 10px 50px;
  border: 3px solid #104B7D;
  border-radius: 0px;
  background: #104B7D;;
  color: #ffffff;
}

.lourd-button {
  padding: 10px 50px;
  border: 3px solid #000;
  border-radius: 0px;
  background: #000;;
  color: #ffffff;
}

.isg-button {
  padding: 10px 50px;
  border: 3px solid #000;
  border-radius: 0px;
  background: #000;;
  color: #ffffff;
}

.argent-button {
  padding: 10px 50px;
  border: 3px solid #303c52;
  border-radius: 0px;
  background: #303c52;
  color: #ffffff;
}
.teaktree-button {
  padding: 10px 50px;
  border: 3px solid #8d8079;
  border-radius: 0px;
  background: #8d8079;
  color: #ffffff;
}
.bluerock-button {
  padding: 10px 50px;
  border: 3px solid #1f2d5e;
  border-radius: 0px;
  background: #1f2d5e;;
  color: #ffffff;
}
.andriolegroup-button{
  padding: 10px 50px;
  border: 3px solid #3f758f;
  border-radius: 0px;
  background: #3f758f;
  color: #ffffff;
}
.greensquare-button{
  padding: 10px 50px;
  border: 3px solid #39803e;
  border-radius: 0px;
  background: #39803e;
  color: #ffffff;
}
.rikoon-button{
  padding: 10px 50px;
  border: 3px solid #40989e;
  border-radius: 0px;
  background: #40989e;
  color: #ffffff;
}
.clearperspective-button {
  padding: 10px 50px;
  border: 3px solid #174A7C;
  border-radius: 0px;
  background: #174A7C;
  color: #ffffff;
}
.fairport-button {
  padding: 10px 50px;
  border: 3px solid #777777;
  border-radius: 0px;
  background: #777777;
  color: #ffffff;
}
.btn-login {
  width: 50%; 
}

.logo {
  max-height: 150px;
}

.cookies-alert {
  display: none;
  -webkit-justify-content: center;
          justify-content: center;
  color: black;
  margin-bottom: 0px;
}

.cookies-link {
  padding-left: 1%;
}

.main-container {
  min-height: calc(100vh - 145px);
}

@media (max-width: 544px) {  
  .h1-font-size {font-size: 1.5rem;} 
}

@media (min-width: 767px) {
  .main-container {
    min-height: calc(100vh - 142px);
  }
}

@media (min-width: 992px) {
  .main-container {
    min-height: calc(100vh - 119px);
  }
}

.footer {
  margin-top: 50px;
  min-height: 50px !important;
  background: #ffff;
}

.footer-font-size {
  font-size: 13px;
}
ul.footer-menu{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  list-style: none;
}
ul.footer-menu li a{
  padding: 0 20px;
  font-size: 17px;
  color: #0073b0;
}
.footer-content{
  font-size: 17px;
}
@media (max-width: 500px) {
  .footer-font-size {
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .footer-font-size {
    font-size: 9px;
  }
}

.link {
  padding-bottom: 26px;
}

.alert-error {
  margin-bottom: calc(100vh - 195px) !important;
}

@media (min-width: 768px) {
  .alert-error {
    margin-bottom: calc(100vh - 192px) !important;
  }
}

@media (min-width: 992px) {
  .alert-error {
    margin-bottom: calc(100vh - 168px) !important;
  }
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  margin: 0 0 20px 0;
}
.grid-2-fit {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 20px 0;
}
.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.7rem;
  margin: 0 0 20px 0;
  grid-auto-rows: 1fr;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 1.7rem;
  margin: 0 0 20px 0;
}
.gap {
  margin: 1rem auto 0rem auto;
}
.advisors-grid {
  background: #fff;
  border-radius: 0.4rem;
  border: 2px solid rgb(230, 229, 229);
  width: 100%;
  border: 1px solid #e8e8ea;
  position: relative;
}
.no-overlap {
  padding: 0.8rem;
  height: 100%;
  border-radius: 0.4rem;
}
.advisors-grid:hover {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.m-advisors {
  margin: 1rem;
}
.advisors-grid:hover {
  cursor: pointer;
}
.info {
  display: grid;
  grid-template-columns: 1fr;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 100%;
  border: 1px solid #fff;
}
.info-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: 100%;
}
.info-l {
  display: grid;
  grid-template-columns: 1fr 2fr;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: 100;
}
.info-2 > * {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: center;
}
.s-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.s-info > * {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-self: center;
          align-self: center;
  margin: 0 auto;
}
.l-info-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 10px;
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: 100%;
}
.l-info-left-1000 {
  margin-left: 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.l-info-left-1200 {
  margin-left: 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.s-info-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: center;
  margin: 10px auto;
}
.advisor-img {
  max-width: 110px;
  border-radius: 50%;
  margin: 0 auto;
}
.s-advisor-img {
  max-width: 110px;
  border-radius: 50%;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  margin: 0 auto;
}
.name {
  color: #000;
  word-break: break-word;
  overflow-wrap: break-word;
  margin: 2px;
  border: none;
}
.name-long {
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 2px;
}
.advisors-contact {
  display: grid;
  grid-template-columns: 10% 90%;
  padding: 0 0 0.6rem 0.6rem;
}
.size {
  font-size: small;
  word-break: break-word;
  overflow-wrap: break-word;
}
.btn-center {
  margin: 0 auto;
}
.t-1 {
  padding-left: 0px;
  border: none;
  background: none;
  margin-bottom: 10px;
}
.btn-gap {
  margin-top: 10px;
}
.advisors-grid:hover .overlaping,
.overlaping:hover {
  box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
}
.overlaping {
  width: calc(100%);
  z-index: 100000;
  padding: 0.8rem;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border: 1px solid #eae9e8;
  border-top: none;
  background: #fff;
  border-radius: 0.4rem;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
  padding: 1rem 1rem 0rem 1rem;
}
.margin-top {
  margin-top: 1rem;
}
.advisors-grid-height-2 {
  height: 282px;
}
@media (min-width: 576px) {
  .contact-margin-left {
    margin-left: 1rem !important;
  }
}
@media (min-width: 300px) {
  .advisors-grid-height {
    height: 260px;
  }
}
@media (min-width: 460px) {
  .advisors-grid-height {
    height: 280px;
  }
}
@media (min-width: 992px) {
  .advisors-grid-height {
    height: 300px;
  }
}
.header {
    /* color: #000000; */
    background-color: #34657f;
    /* padding: 15px; */
    margin-left: -6px;
  }
  .modal.body {
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    background-color: #ffffff;
  }
  .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
    background-color: #ffffff;
  }
  .ht-gray-stripe-fill {
    background-repeat: repeat;
    background-position-x: center;
    background-image: url("https://images.ctfassets.net/nac4rsvtoh64/3FBGhXZvG3TDagKv1XlnP/13e23e5dc519637caacde721bcbbd732/ht-gray-stripe-fill.png?fm=webp");
  }
  .min-height-25 {
    min-height: 25px;
  }
  .p-btn{
    height:35px
  }

.layout {
  width: 100%;
  height: 100%;
}

.header {
  height: 100px;
  background: #ffffff;
}

.header row {
  height: 100px;
}

.logo img {
  width: auto;
  height: 85px;
}

@media (max-width: 500px) {
  .logo img {
    /* width: auto;
    height: 34px; */
    object-fit: contain;
  }
}

.userInfo button {
  padding-right: 0px;
}

.signOut {
  float: right;
}

.card-horizontal {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.main {
  margin: 3.5rem 6.8125rem 0 6.8125rem;
}

.card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}



.card-content {
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.card-content a {
  color: black;
  text-decoration: none;
}

.card-content a:hover {
  color: black;
  text-decoration: none;
}

.advisor-content {
  padding: 15px;
}

.advisorName {
  font-size: 32px;
  line-height: 36px;
  color: #000000;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.advisor-address {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #0073b0;
  margin-top: 1.5rem;
  white-space: nowrap;
}

.advisor-address img {
  margin-right: 0.5rem;
}

.addressIcon {
  width: 15px;
  height: 20px;
}

.emailIcon {
  width: 20px;
  height: 15px;
  margin-top: 5px;
}

.email-text {
  word-break: break-word;
}

.contact-icon {
  width: 20px;
  height: auto;
}

.contact {
  color: #0073b0;
  white-space: break-spaces;
}

.phoneIcon {
  width: 20px;
  height: 20px;
}

.schedule {
  width: 100%;
  height: 48px;
  background: #34657f;
  border-radius: 51px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 1.5rem;
}

.schedule-wide {
  width: 142px;
  height: 48px;
  background: #34657f;
  border-radius: 51px;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1.5rem;
}

.schedule-wide span {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.schedule-wide img {
  width: 17.5px;
  height: 20px;
  margin: 0 0.5rem 0.313rem 0;
}

.s-schedule {
  height: 40px;
}

.schedule img {
  width: 17.5px;
  height: 20px;
  margin: 0 0.5rem 0.313rem 0;
}

.schedule span {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.card {
  box-shadow: none;
}

.vendor {
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding: 1.5rem;
  border: 1px solid #eae9e8;
}

.vendor:hover {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.line {
  width: 316px;
  height: 1px;
  background: #bfb8af;
}


.text-title {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 1rem;
}

.col-margin {
  padding-right: 0 !important;
}

.userInfo {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: black;
  margin-top: 1.5rem;
}

.userInfo-row {
  margin-bottom: 0.625rem;
}

.userInfo img {
  margin-right: 0.5rem;
}

.card-gutter {
  padding: 0 0 1.5rem 0 !important;
  margin: 0 !important;
}

.ellipse {
  border-radius: 50%;
  height: 146px;
  width: 146px;
}

.footer {
  min-height: 100px;
}

.decoration-none {
  text-decoration: none !important;
}
.c-pointer{
  cursor: pointer;
}

.card-padding {
  padding-bottom: 30px;
}

.account-link {
  border: 2px solid #34657f;
  border-radius: 51px;
  color: #34657f;
}

.portal-logo-container {
  height: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.portal-logo {
  max-height: 65px;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}

@-moz-document url-prefix() {
  .portal-logo {
    width: 292.5px;
  }
}

.portal-logo-width {
  width: 100%;
}

.btn-sign-out {
  padding: 0 !important;
}

.contact-user {
  color: #000000;
}

.logo-wide {
  height: auto;
  width: 250px;
  object-fit: cover;
}

.sign-out {
  color: #000000 !important;
}
.invoice {
    display: -webkit-flex;
    display: flex;
    background: #34657f;
    padding: 8px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
  }
  .invoice-link {
    background: #ffffff;
    padding: 0 2rem;
    border-radius: 2rem;
    font-size: 18px;
    color: #000000;
    margin-right: 0;
  }
  
  body.active-modal {
    overflow-y: hidden;
  
  }
  .overlay {
    background: black;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    opacity: 0.8;
    z-index: 99999999999;
  
  }
  .modal-content {
    position: absolute;
    top: 251px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    line-height: 1.8;
    background: #ffff;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 500px;
    min-width: 300px;
    min-height: 400px;
    color: red;
    font-size: 20px;
    z-index: 999999999999;
  }
  .onlinepay {
    background-color: black;
    color: #f1f1f1;
    width: 150px;
    margin: 10px auto;
    text-align: center;
  }

.container-cookies-info {
  padding: 36px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-height .cookies {
  background-color: #ffff !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.title-info {
  color: #2f5496;
  font-size: 21px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  color: #2f5496;
  margin-bottom: 20px;
  margin-top: 20px;
}

.list-group-info {
  list-style: decimal inside;
  padding-left: 50px !important;
}

.list-group-info-item {
  display: list-item !important;
  background-color: #0000 !important;
  border: 0 !important;
  padding: 0 !important;
}

.note {
  margin-top: -20px;
}

.terms-of-use {
  font-family: Overpass;
  font-style: normal;
  font-weight: 700;
  min-height: 85px;
  padding-top: 25px;
  margin-left: 40px;
  color: #3498db;
}
.ht-gray-stripe-fill {
  background-repeat: repeat;

  background-position-x: center;

  background-image: url("https://images.ctfassets.net/nac4rsvtoh64/3FBGhXZvG3TDagKv1XlnP/13e23e5dc519637caacde721bcbbd732/ht-gray-stripe-fill.png?fm=webp");
}
.min-height-50 {
  min-height: 50px;
}
.main-head {
  font-family: Overpass;
  font-style: normal;
  font-weight: 700;
  padding-top: 50px;
  font-size: 2.5rem;
}

.accordion-button {
  border: 0;
  background: none;
}
.accordion-heading {
  font-size: 18px;
  font-weight: bold;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
.card-horizontal {
  margin-bottom: 10px;
  padding: 0 0 0 20px;
  height: 50px;
  line-height: 50px;
}
.accordion-item {
  margin-bottom: 10px;
  padding: 0;
  border-top: 1px solid #dee2e6 !important;
}
.accordion-button:focus {
  border: 0;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  color: #212529;
}
.icon {
  margin-top: -4px;
  font-size: 24px;
  margin-right: 10px;
}
.filter-icon {
  margin-top: -4px;
  font-size: 30px;
  margin-right: 10px;
}
.duty-icon {
  color: #fa9600;
  position:absolute;
  right: 15px;
  top: 13px;
}
h1, h2, h3, h4, h5, h6 {
 font-family: Overpass!important; 
}
.grid-icons {
  font-size: 22px;
}
.filter-heading {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #b1b1b1 !important;
}
.filter-container {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  background-color: #f8f8f8;
  font-size: 15px;
  width: 92%;
  padding: 16px;
  top: 32px;
}
.filter-text {
  border-bottom: 2px solid #e7e7e7;
  /* font-size: 28px; */
  margin-bottom: 20px;
  margin-right: 200px;
  float: left;
  width: 30%;
  margin-left: 750px;
}
.filterTable {
  width: 30%;
}
.form-select {
  padding: 5px 35px 0 10px;
  font-size: 15px;
  border: 0;
  border-radius: 0;
  line-height: 17px;
  margin-left: 8px;
}
.form-select:focus {
  border: 0 !important;
  outline: 0;
  box-shadow: none !important;
}
.table td,
.table th {
  border: 0 !important;
}
.table > :not(caption) > * > * {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(250 248 246) !important;
  box-shadow: none;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  box-shadow: none;
}
#filter-container input:focus {
  border-bottom-color: #4f41e9;
}

.file-icon {
  font-size: 25px;
  margin-left: 40;
}
.eye-icon {
  font-size: 25px;
  padding: 2px 4px;

  margin-left: 200px;
  -webkit-align-items: center;
          align-items: center;
}
.download-icon {
  padding: 10px 20;
  margin-left: 20px;
  margin-bottom: 8px;
  margin-right: 20px;
  -webkit-align-items: center;
          align-items: center;
}

.table {
  font-size: 15px;
  width: 100%;
  margin-right: 40px;
}

.table-fixed tr,
.table-fixed td,
.table-fixed th {
  /* padding: auto; */
  font-size: 80px;
  margin-left: 40px;
  padding: 10px 30px;
  margin-right: 70px;
}
.table-fixed td {
  padding: 40px 80px;
}

.table-header,
tr,
td,
th {
  /* padding: auto; */
  font-size: 15px;
  padding: 20px 40px;
  margin-right: 20px;
}
.table-Header {
  margin-left: 50px;
}

.table-body,
tr,
th,
td {
  margin-right: 40px;
  font-size: 15px;
  padding: 20px 40px;
}
.page-item:first-child .page-link {
  border-radius: none;
}
.pagination {
  display: inline-block;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 100px !important;
  margin-right: 150px;
  margin-top: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 !important;
}
.pagination div {
  margin: 5px !important;
}
.pagination div div {
  border-radius: 20% !important;
}
#files-text {
  margin-left: 530px;
  display: inline;
  margin-bottom: 100px;
  font-size: 20px;
}

.files-per-text {
  font-size: 20px;
  margin-bottom: 70px;
  padding: 10px 20px;
  margin-bottom: 50px;
}
.horizontal-line {
  margin-left: 640px;
  padding: 10px 20px;

  width: 20%;
  margin-bottom: 30;
}

hr-line {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
  margin-bottom: 30;
  height: 4px;
  background-color: gray;
  border-width: "0";
  color: gray;
  font-size: 20px;
}

.vault-img {
  display: -webkit-flex;
  display: flex;
  margin-top: 90px;
  margin-bottom: 25px;

  -webkit-justify-content: space-between;

          justify-content: space-between;
  /* margin-left: 320px; */
}

#button {
  /* border: 2px solid; */
  padding: 8px 32px;
}
#button {
  background-color: #e7e7e7;
  color: black;
  border: 2px solid #e7e7e7;
}
#button:hover {
  background-color: #0275d8;

  color: white;
}

.download-file-icon {
  font-size: 28px;
  margin-top: 10px;
  color: black;
}
.file-download-text {
  font-size: 27px;
  margin-top: 30px;
  color: black;
  margin-left: 0px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}
.modal-button {
  margin-left: 10rem;
  margin-bottom: 40px;
}
.modal-pop {
  width: 40%;
  size: 30px;
}

.pdf-viewer-container {
  background-color: #fff;
  left: 0;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
}
.pdf-viewer-preview {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}
.button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding-bottom: 30px;
}
.button-align {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}
.download-button {
  border: none;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 4px;
}
.download-button:hover {
  background-color: #41abe9;
  color: #fff;
}
.iframe-container {
  height: 90%;
}
.pdfViwer {
  cursor: pointer;
}

.heading{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height:700px
}
.maintcss{
    --ht-pri-dk-gray: #4B4F54;
    --ht-pri-orange: #FF6A14;
    --ht-pri-lt-gray: #BFB8AF;
    --ht-sec-blue: #34657F;
    --ht-sec-green: #5D6439;
    --ht-sec-orange: #D78825;
    --ht-sec-red: #9A3324;
    --well-thview-brown: #212529;
    padding: 100px;
    font-family: 'Overpass', sans-serif;
    background-color: #f5f4f4;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'EB Garamond', serif;
}

h1 {
    color: var(--ht-sec-green);
}

h2 {
    color: var(--ht-sec-orange);
}

p {
    color: var(--ht-pri-dk-gray);
}
