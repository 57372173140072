.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  margin: 0 0 20px 0;
}
.grid-2-fit {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 20px 0;
}
.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.7rem;
  margin: 0 0 20px 0;
  grid-auto-rows: 1fr;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 1.7rem;
  margin: 0 0 20px 0;
}
.gap {
  margin: 1rem auto 0rem auto;
}
.advisors-grid {
  background: #fff;
  border-radius: 0.4rem;
  border: 2px solid rgb(230, 229, 229);
  width: 100%;
  border: 1px solid #e8e8ea;
  position: relative;
}
.no-overlap {
  padding: 0.8rem;
  height: 100%;
  border-radius: 0.4rem;
}
.advisors-grid:hover {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.m-advisors {
  margin: 1rem;
}
.advisors-grid:hover {
  cursor: pointer;
}
.info {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  border: 1px solid #fff;
}
.info-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}
.info-l {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  height: 100;
}
.info-2 > * {
  align-items: flex-start;
  text-align: center;
}
.s-info {
  display: flex;
  flex-direction: column;
}
.s-info > * {
  align-items: flex-start;
  align-self: center;
  margin: 0 auto;
}
.l-info-left {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align: left;
  align-items: flex-start;
  height: 100%;
}
.l-info-left-1000 {
  margin-left: 10px;
  justify-content: space-between;
}
.l-info-left-1200 {
  margin-left: 10px;
  justify-content: space-between;
}
.s-info-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 10px auto;
}
.advisor-img {
  max-width: 110px;
  border-radius: 50%;
  margin: 0 auto;
}
.s-advisor-img {
  max-width: 110px;
  border-radius: 50%;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
}
.name {
  color: #000;
  word-break: break-word;
  overflow-wrap: break-word;
  margin: 2px;
  border: none;
}
.name-long {
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 2px;
}
.advisors-contact {
  display: grid;
  grid-template-columns: 10% 90%;
  padding: 0 0 0.6rem 0.6rem;
}
.size {
  font-size: small;
  word-break: break-word;
  overflow-wrap: break-word;
}
.btn-center {
  margin: 0 auto;
}
.t-1 {
  padding-left: 0px;
  border: none;
  background: none;
  margin-bottom: 10px;
}
.btn-gap {
  margin-top: 10px;
}
.advisors-grid:hover .overlaping,
.overlaping:hover {
  box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
}
.overlaping {
  width: calc(100%);
  z-index: 100000;
  padding: 0.8rem;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border: 1px solid #eae9e8;
  border-top: none;
  background: #fff;
  border-radius: 0.4rem;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
  padding: 1rem 1rem 0rem 1rem;
}
.margin-top {
  margin-top: 1rem;
}
.advisors-grid-height-2 {
  height: 282px;
}
@media (min-width: 576px) {
  .contact-margin-left {
    margin-left: 1rem !important;
  }
}
@media (min-width: 300px) {
  .advisors-grid-height {
    height: 260px;
  }
}
@media (min-width: 460px) {
  .advisors-grid-height {
    height: 280px;
  }
}
@media (min-width: 992px) {
  .advisors-grid-height {
    height: 300px;
  }
}