.container-cookies-info {
  padding: 36px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-height .cookies {
  background-color: #ffff !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.title-info {
  color: #2f5496;
  font-size: 21px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  color: #2f5496;
  margin-bottom: 20px;
  margin-top: 20px;
}

.list-group-info {
  list-style: decimal inside;
  padding-left: 50px !important;
}

.list-group-info-item {
  display: list-item !important;
  background-color: #0000 !important;
  border: 0 !important;
  padding: 0 !important;
}

.note {
  margin-top: -20px;
}
